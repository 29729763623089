body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 25px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
	/* text-align: center; */
	margin-top: 50px;
	margin-bottom: 50px;
}

header img {
	height: 200px;
}

.wrapper {
	width: 90%;
	margin: 0px auto;
	max-width: 1500px;
}

.flex {
	display: flex;
	gap: 20px;
	align-items: center;
	/*  */
}

.col {
	flex-grow: 1;
	flex-wrap: wrap;
}

.video {
	text-align: center;
	min-width: 500px;
	flex-grow: 1;
}

.watch_video_text {
	margin-top: 10px;
}

footer ul {
	list-style: none;
	margin: 0px;
	padding: 0px;
}

footer ul li {
	display: inline;
	margin: 0px;
	padding: 10px;
}

footer ul li img {
	height: 30px;
}

@media (max-width: 1280px) {
	.flex {
		flex-wrap: wrap;
	}
}